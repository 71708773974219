// theme.js
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    body: 'Nunito, system-ui, sans-serif',
    heading: 'Nunito, system-ui, sans-serif',
    mono: 'Menlo, monospace',
  },
  breakpoints: {
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "120em", // Full HD (1920px / 16 = 120em)
    "2xl": "160em", // 4K (2560px / 16 = 160em)
  },
});

export default theme;