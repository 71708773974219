import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';

const DisconnectDialog = ({ isOpen, onClose, onDisconnect }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent mt="0" borderRadius="1rem">
        <VStack spacing={2} alignItems="flex-start" width="80%" mx="auto">
          <ModalHeader mt={0}></ModalHeader>
          <ModalHeader
            fontWeight="300"
            fontSize="1.35rem"
            letterSpacing="0"
            fontFamily="'Tilt Warp', cursive"
          >
            Thank you for visiting us! We hope you managed to make a mint.
          </ModalHeader>
        </VStack>
        <ModalCloseButton size="lg" color="gray.500" />
        <ModalBody>
          <VStack spacing={4} alignItems="center" width="80%" mx="auto" mb="3rem">
            <Button
              onClick={onDisconnect}
              fontWeight="bold"
              fontSize="lg"
              borderRadius="full"
              size="lg"
              width="15rem"
              alignSelf="flex-start"
              backgroundColor="rgb(246,250,245)"
              _hover={{ backgroundColor: 'rgb(232,232,232)'}}
              borderColor="black"
              borderWidth={1}
            >
              Disconnect
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DisconnectDialog;
