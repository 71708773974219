import React from 'react';
import { Box, Button, Heading, Image, Text } from '@chakra-ui/react';
import { useState } from "react";



const MintSection = ({
  walletConnected,
  openWalletModal,
  onOpen,
  backgroundImage,
  smallImage,
  mintNFT,
  numNFTsToMint
}) => {

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
    backgroundImage={`url(${backgroundImage})`}
    backgroundSize="cover"
    backgroundPosition="center"
    py={10}
    textAlign="center"
    position="relative"
    width="100vw"
    height="60vh"
    mt="0 !important"
  >
    <Box
      position="absolute"
      top={{ base: '85%', md:"81%", lg: '81%', xl: '81%', '2xl': '81%'}}
      left={{ base: '90%', md:"77%", lg: '57%', xl: '57%', '2xl': '57%'}}
      transform="translate(-50%, -50%)"
      zIndex={1}
    >
      <Image
        src={smallImage}
        alt="rabbit"
        maxWidth={{ base: '80vw', md:"37vh", xl: '37vh', '2xl': '30vh'}}
        height="auto"
        position="relative"
      />
      <Box
        position="absolute"
        left={{ base: '-35%', md:"-40%", lg: '-50%', xl: '-40%', '2xl': '-30%'}}
        top={{ base: '42%', md:"50%", lg: '50%', xl: '45%', '2xl': '50%'}}
        transform="translate(0%, -50%)"
        mt="2rem"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Text
          color="#e0fd2c"
          fontFamily="'Nunito', sans-serif"
          fontSize={{ base: '1.3rem', md:"1.5rem", xl: '1.5rem', '2xl': '1.5rem' }}
          bgColor="#035772"
          display="inline"
          fontWeight="600"
        >
          Minting is free!*
        </Text>
        <Button
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => {
            if (!walletConnected) {
              openWalletModal();
            } else {
              onOpen();
              // mintNFT(numNFTsToMint);
            }
          }}
          mt="1rem"
          borderRadius="50px"
          borderWidth="3px"
          borderColor="#ff3d6e"
          bgColor={isHovered ? "#ff3d6e !important" : "#f6faf5"}
          fontFamily="'Rammetto One', cursive"
          fontWeight="400"
          width="7.5rem"
          height="3rem"
        >
          <Text
            color={isHovered ? "white" : "#ff3d6e"}
            fontSize={{
              base: "1.2rem",
              md: "1.8rem",
              xl: "1.2rem",
              "2xl": "1.2rem",
            }}
          >
            Mint
          </Text>
        </Button>
        <Text fontFamily="NunitoBold" color="white" mt={0}>
          *Gas fees apply
        </Text>
      </Box>
    </Box>

    <Box
      zIndex={2}
      position="absolute"
      left={{ base: '45%', lg: '35%', xl: '35%', '2xl': '40%'}}
      top="41%"
      transform="translate(-50%, -50%)"
    >
      <Box textAlign="left">
        <Heading
          as="h1"
          fontSize={{ base: '3rem', md: '5rem', lg: '4rem', xl: '7rem', '2xl': '7rem'}}
          color="#ff4f7b" //H:345 S:69 B:100
          fontWeight="bold"
          fontFamily="'Rammetto One', cursive"
        >
          <Text mb={{ base: '-1rem', md: '-1rem', lg: '-1.2rem', xl: '-1.6rem' }}>CRYPTO</Text>
          <Text>FRANKI</Text>
        </Heading>
        <Box>
          <Text
            color="#f6f9f5"
            fontFamily="'Nunito', sans-serif"
            fontSize={{base: '1.2rem', lg: '1rem', xl: '1.2rem', '2xl': '1.5rem' }}
            lineHeight="1.3"
            width={{ base: '70vw', lg: '40vw', xl: '30vw', '2xl': '30vw' }}
            >
            <Box bgColor="#035772" display={{ base: "none", md: "inline" }} py="0.2rem" px="0.2rem">
            Crypto Franki is a unique NFT collection, randomly generated on the Ethereum blockchain. Each Franki boasts a one-of-a-kind mix of body, face, and outfit traits. With only 1000 Frankis available, don't miss this exclusive opportunity. Get your Crypto Franki today!
            </Box>
            <Box bgColor="#035772" display={{ base: "inline", md: "none" }} py="0.2rem" px="0.2rem">
            Discover Crypto Franki, a limited collection of 1000 unique NFTs on Ethereum. Each features a distinct combination of elements, making them truly special. Don't miss out - secure your Crypto Franki today!
            </Box>
          </Text>
        </Box>
      </Box>
    </Box>
  </Box>
  );
};

export default MintSection;
