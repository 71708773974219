import React from 'react';
import { Box, Flex, Heading, Text, Image, HStack  } from '@chakra-ui/react';
import { Grid, GridItem } from '@chakra-ui/react';
import { FaTwitter, FaDiscord, FaFacebookF, FaInstagram } from 'react-icons/fa';

import vellit from './../assets/Vellit.png';
import voldemar from './../assets/Vol-De-Mar.png';
import lusi from './../assets/Lusi.png';

const TeamMember = ({ name, role, imageSrc, socialLinks, description, color }) => {
  return (
    <Box textAlign="center" maxW={{ base: '70vw', md: '20vw', lg: '20vw', xl: '15vw', '2xl': '15vw' }}  
      height={{ base: 'none', md: '50vh', lg: '50vh', xl: '60vh', '2xl': '47vh' }}
    >
      <Flex flexDirection="column" justifyContent="space-between" h="100%">
        <Box>
          <Image
            src={imageSrc}
            alt={name}
            objectFit="cover"
            mx="auto"
            mb="4"
          />
          <Text fontSize="1.8rem" fontFamily="'Changa One', cursive" fontWeight="400" color={color} mt="2rem">
            {name}
          </Text>
          <Text fontWeight="700" mt="0.85rem" fontSize="1.1rem">{role}</Text>
          <Text mt={{ base: '0.8rem', md: '2rem'}} letterSpacing="0.02em" fontWeight="400" fontSize="1.1rem" lineHeight="1.5rem"
            maxW={{ base: '70vw', md: '100%', lg: '100%', xl: '100%', '2xl': '12vw' }} mx="auto">{description}</Text>
        </Box>
        {/* social media links */}
        <Box pt="1rem">
          <HStack justifyContent="center" spacing={4} fontSize="1.8rem">
            {socialLinks.facebook && (
              <a href={socialLinks.facebook} target="_blank" rel="noreferrer">
                <FaFacebookF />
              </a>
            )}
            {socialLinks.instagram && (
              <a href={socialLinks.instagram} target="_blank" rel="noreferrer">
                <FaInstagram />
              </a>
            )}
            {socialLinks.twitter && (
              <a href={socialLinks.twitter} target="_blank" rel="noreferrer">
                <FaTwitter />
              </a>
            )}
            {socialLinks.discord && (
              <a href={socialLinks.discord} target="_blank" rel="noreferrer">
                <FaDiscord />
              </a>
            )}
          </HStack>
        </Box>
      </Flex>
    </Box>
  );
};


const TeamSection = () => {
  return (
    <Box my="8" width="100%" bgColor="rgb(246, 249, 245)" height={{md: "100vh", xl:"90vh"}} id="team">
      <Flex alignItems="center" justifyContent="center" flexDirection="column" height="100%"> 
        <Heading as="h2" size="lg" mb="3.3rem" ml={{ md: "-35vw", "2xl": "-30vw" }}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Text className="section-header header-normal" color="#034459" fontSize="1.7rem">
              Meet the
            </Text>
            <Text className="section-header header-normal" marginLeft="1rem" fontSize="1.7rem">
              Team
            </Text>
          </Box>
        </Heading>
        <Flex flexDirection={{ base: "column", md: "row" }} justifyContent="center" alignItems="center" wrap="wrap">
          <Box>
            <Flex alignItems="flex-start" justifyContent="center">
              <TeamMember
                name="Vellit"
                role="Creator"
                description="A programmer, IT specialist, software engineer, and website developer."
                imageSrc={vellit}
                color="rgb(171, 70, 255);"
                socialLinks={{
                  twitter: "https://twitter.com/@vellit",
                  discord: "https://discord.com/users/Vit#5366",
                  // facebook: 'https://facebook.com/vellit',
                  // instagram: 'https://instagram.com/vellit',
                }}
              />
            </Flex>
          </Box>
          <Box mx={{ md: "5vw" }} my={{ base: "3vh", md: "0" }}>
            <Flex justifyContent="center" alignItems="flex-start" >
              <TeamMember
                name="Lusi"
                role="Speaker"
                description="A marketing specialist, social media page development manager, and SEO expert."
                imageSrc={lusi}
                color="rgb(238, 64, 134);"
                socialLinks={{
                  twitter: "https://twitter.com/Franki_NFT",
                  discord: "https://discord.com/users/Lusi#6216",
                  // facebook: 'https://facebook.com/',
                  // instagram: 'https://instagram.com/',
                }}
              />
            </Flex>
          </Box>
          <Box>
            <Flex justifyContent="center" alignItems="flex-start" >
              <TeamMember
                name="Vol De Mar"
                role="Artist"
                description="An NFT, portrait, and tattoo artist skilled in comics, WPAP, POP ART, and pencil drawing styles."
                imageSrc={voldemar}
                color="rgb(255, 61, 111)"
                socialLinks={{
                  facebook: "https://www.facebook.com/voldemar.art.admin/",
                  instagram: "https://instagram.com/vol_de_mar_art?igshid=ZDdkNTZiNTM=",
                  twitter: 'https://twitter.com/@Vol_De_Mar_art',
                  discord: 'https://discord.com/users/Vol De Mar#0509',
                }}
              />
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TeamSection;

