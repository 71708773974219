import React, { useRef, useState, useEffect  } from 'react';
import { Box, Flex, Text, Heading, Image, VStack, Stack, Button, Modal, ModalOverlay, ModalContent, ModalCloseButton, HStack, ModalHeader, ModalBody, ModalFooter } from '@chakra-ui/react';
import { Link, IconButton, Icon} from '@chakra-ui/react';
import { FaExpandArrowsAlt, FaCompressArrowsAlt } from 'react-icons/fa';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import Marquee from 'react-marquee-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import img1 from '../assets/For Galleri site/Franki 1.png';
import img2 from '../assets/For Galleri site/Franki 201.png';
import img3 from '../assets/For Galleri site/Franki 321.png';
import img4 from '../assets/For Galleri site/Franki 352.png';
import img5 from '../assets/For Galleri site/Franki 485.png';
import img6 from '../assets/For Galleri site/Franki 500.png';
import img7 from '../assets/For Galleri site/Franki 683.png';
import img8 from '../assets/For Galleri site/Franki 691.png';
import img9 from '../assets/For Galleri site/Franki 697.png';

import img10 from '../assets/For Galleri site/Franki 702.png';
import img11 from '../assets/For Galleri site/Franki 713.png';
import img12 from '../assets/For Galleri site/Franki 729.png';
import img13 from '../assets/For Galleri site/Franki 773.png';
import img14 from '../assets/For Galleri site/Franki 858.png';
import img15 from '../assets/For Galleri site/Franki 895.png';
import img16 from '../assets/For Galleri site/Franki 945.png';
import img17 from '../assets/For Galleri site/Franki 970.png';

import left from "../assets/Icons site/left.png";
import right from "../assets/Icons site/right.png";
import close from "../assets/Icons site/close.png";
import expand from "../assets/Icons site/expand.png";
import collapse from "../assets/Icons site/collapse.png";

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9];
const images2 = [img10, img11, img12, img13, img14, img15, img16, img17];

const traitsData = [
  {
    image: img1,
    number: "1",
    traits: [
      'BACKGROUND: Scarlet red',
      'BEARD: Goatee',
      'BODY: Crocodile',
      'EARDROP: Ring',
      'EYES: Indifferent',
      'HAIR: Bald hairstyle',
      'HAT: Baseball cap',
      'MOUTH: Bit his lip',
      'MUSTACHE: Stubble shreds 2',
      'NECK: Neckerchief',
      'NOSE: With hair',
      'TATTOO FACE: Drop',
      'TATTOO NECK: Balance',
      'UNDERSHIRT: Butcher apron'
    ],
  },
  {
    image: img2,
    number: "201",
    traits: [
      'BACKGROUND: Orange',
      'BEARD: Shreds 1',
      'BODY: Crocodile',
      'EARDROP: Fish',
      'EYES: Indifferent',
      'HAIR: Pompadour',
      'MOUTH: Smile with tongue',
      'NECK: Cone with potion',
      'NOSE: Piggy',
      'OUTERWEAR: Vest-mantle',
      'TATTOO FACE: Star',
      'TATTOO NECK: Crypto fan'
    ],
  },
  {
    image: img3,
    number: "321",
    traits: [
      'BACKGROUND: Scarlet red',
      'BODY: Yellow',
      'EARDROP: Claw',
      'EYES: Half-open',
      'HAIR: Bald hairstyle',
      'HAT: Stocking cap',
      'MOUTH: Smile with tongue',
      'MUSTACHE: Straight',
      'NECK: Gun cartridge',
      'NOSE: Piggy',
      'OUTERWEAR: Jacket with shirt',
      'TATTOO NECK: Crypto fan',
      'UNDERSHIRT: Light undershirt',
      
    ],
  },
  {
    image: img4,
    number: "352",
    traits: [
      'BACKGROUND: Orange',
      'BODY: Light',
      'EARDROP: Pearl',
      'EYES: Indifferent',
      'HAIR: Bald hairstyle',
      'HAT: Knitted hat',
      'MOUTH: Surprised',
      'MUSTACHE: Fu Manchu short',
      'NOSE: Piggy',
      'OUTERWEAR: Overcoat',
      'TATTOO FACE: 21',
      'TATTOO NECK: Estas en mi corazon',
      'UNDERSHIRT: Dirty T-shirt',
      
    ],
  },
  {
    image: img5,
    number: "485",
    traits: [
      'BACKGROUND: Pink',
      'BEARD: Shreds 1',
      'BODY: Light',
      'EARDROP: Plug',
      'EYES: Sleepy',
      'HAIR: Bob hairstyle',
      'HAT: Cowboy hat',
      'MOUTH: Bit his lip',
      'MUSTACHE: Stubble',
      'NECK: Beads with skull',
      'NOSE: Broken',
      'OUTERWEAR: Polo shirt',
      'TATTOO FACE: Rock',
      'TATTOO NECK: Go to the moon',
      'UNDERSHIRT: Transparent T-shirt',
      
    ],
  },
  {
    image: img6,
    number: "500",
    traits: [
      'BACKGROUND: Blue',
      'BODY: Light',
      'EARDROP: Pendent',
      'EYES: Bruise',
      'HAIR: High temple',
      'HAT: Peaked cap',
      'MOUTH: Spit',
      'NOSE: Piggy',
      'OUTERWEAR: Overcoat',
      'TATTOO FACE: Anchor',
      'TATTOO NECK: Balance',
      'UNDERSHIRT: Sailors jersey',
      
    ],
  },
  {
    image: img7,
    number: "683",
    traits: [
      'BACKGROUND: White',
      'BODY: Coral',
      'EARDROP: Earpiece',
      'EYES: Half-open',
      'HAIR: Bun hairstyle',
      'HAT: Top hat',
      'MOUTH: Surprised',
      'MUSTACHE: Stubble shreds 2',
      'NECK: Beads with skull',
      'NOSE: Blue',
      'OUTERWEAR: Denim vest',
      'TATTOO NECK: Ego sum perfecta inperfecta',
      'UNDERSHIRT: Sailors jersey',
      
    ],
  },
  {
    image: img8,
    number: "691",
    traits: [
      'BACKGROUND: White',
      'BODY: Yellow',
      'EARDROP: Ring',
      'EYES: Apprehensively surprised',
      'HAIR: High temple',
      'MOUTH: Malicious smile',
      'NECK: Pentagram sign coin',
      'NOSE: Broken',
      'OUTERWEAR: Jacket with fur collar',
      'TATTOO NECK: Crypto fan',
      'UNDERSHIRT: Beach jersey',
      
    ],
  },
  {
    image: img9,
    number: "697",
    traits: [
      'BACKGROUND: Orange',
      'BODY: Blue',
      'EARDROP: Claw',
      'EYES: Black',
      'HAIR: Pompadour',
      'HAT: Ushanka',
      'MOUTH: Surprised',
      'NOSE: Aquiline nose',
      'OUTERWEAR: Jacket with shirt',
      'TATTOO FACE: Kiss mark',
      'TATTOO NECK: Never say never',
      'UNDERSHIRT: Plunging neck T-shirt',
      
    ],
  },
  {
    image: img10,
    number: "702",
    traits: [
      'BACKGROUND: Scarlet red',
      'BODY: Coral',
      'EARDROP: Plug',
      'EYES: Bulging',
      'HAIR: Bob hairstyle',
      'MOUTH: Franki smile',
      'NECK: Beads with skull',
      'NOSE: Blue',
      'OUTERWEAR: Polo shirt',
      'TATTOO FACE: Heart 1',
      'TATTOO NECK: Ego sum perfecta inperfecta',
      'UNDERSHIRT: Dirty T-shirt',
      
    ],
  },
  {
    image: img11,
    number: "713",
    traits: [
      'BACKGROUND: Canary',
      'BODY: Crocodile',
      'EARDROP: Scull',
      'EYES: Closed',
      'HAIR: High temple',
      'HAT: Baseball cap',
      'MOUTH: Surprised',
      'NECK: Beads with skull',
      'NOSE: Round holes',
      'TATTOO FACE: Fly',
      'UNDERSHIRT: T-shirt',
      
    ],
  },
  {
    image: img12,
    number: "729",
    traits: [
      'BACKGROUND: Scarlet red',
      'BODY: Yellow',
      'EARDROP: Earpiece',
      'EYES: Sleepy',
      'HAIR: Bun hairstyle',
      'HAT: Kerchief',
      'MOUTH: Angry',
      'MUSTACHE: Fluff',
      'NOSE: Piggy',
      'OUTERWEAR: Vest-mantle',
      'TATTOO FACE: Anchor',
      'TATTOO NECK: Go to the moon',
      'UNDERSHIRT: T-shirt',
      
    ],
  },
  {
    image: img13,
    number: "773",
    traits: [
      'BACKGROUND: Blue',
      'BEARD: Short straight 1',
      'BODY: Crocodile',
      'EYES: Angry',
      'HAIR: Bun hairstyle',
      'HAT: Stocking cap',
      'MOUTH: Smile braces cigarette',
      'MUSTACHE: Fluff',
      'NOSE: Broken',
      'OUTERWEAR: Overcoat',
      'TATTOO NECK: Love saves the world',
      'UNDERSHIRT: Plunging neck T-shirt',
      
    ],
  },
  {
    image: img14,
    number: "858",
    traits: [
      'BACKGROUND: Rabbit',
      'BODY: Light',
      'EYES: Bruise',
      'HAIR: High temple',
      'MOUTH: Smile with drool',
      'MUSTACHE: Straight',
      'NECK: Choker 2',
      'NOSE: Broken',
      'OUTERWEAR: Overcoat',
      'TATTOO FACE: Rock',
      'TATTOO NECK: Love saves the world',
      'UNDERSHIRT: Plunging neck T-shirt',
      
    ],
  },
  {
    image: img15,
    number: "895",
    traits: [
      'BACKGROUND: Scarlet red',
      'BEARD: Stubble 1',
      'BODY: Yellow',
      'EARDROP: Pendent',
      'EYES: Half-open',
      'HAIR: High temple',
      'HAT: Panama hat',
      'MOUTH: Surprised',
      'NOSE: Round holes',
      'OUTERWEAR: Overcoat',
      'TATTOO FACE: Heart 1',
      'TATTOO NECK: Go to the moon',
      'UNDERSHIRT: Dirty shirt',
      
    ],
  },
  {
    image: img16,
    number: "945",
    traits: [
      'BACKGROUND: White',
      'BEARD: Short straight 1',
      'BODY: Blue',
      'EARDROP: Plug',
      'EYES: Glasses',
      'HAIR: High temple',
      'HAT: Panama hat',
      'MOUTH: Smile with drool',
      'NOSE: Clown',
      'OUTERWEAR: Perfecto',
      'TATTOO NECK: Estas en mi corazon',
      'UNDERSHIRT: Butcher apron',
      
    ],
  },
  {
    image: img17,
    number: "970",
    traits: [
      'BACKGROUND: Blue',
      'BEARD: Shreds 2',
      'BODY: Brown',
      'EARDROP: Pendent',
      'EYES: Bruise',
      'HAIR: Bald hairstyle',
      'HAT: Pirate Hat',
      'MOUTH: Angry',
      'MUSTACHE: Stubble',
      'NECK: Bow',
      'NOSE: With a ring',
      'OUTERWEAR: Denim vest',
      'TATTOO NECK: Love saves the world',
      'UNDERSHIRT: Light undershirt',
      
    ],
  },
];

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [currentTraits, setCurrentTraits] = useState([]);
  const [currentNumber, setCurrentNumber] = useState(0);

  const [isFullscreenModalOpen, setIsFullscreenModalOpen] = useState(false);
  const openFullscreenModal = () => setIsFullscreenModalOpen(true);
  const closeFullscreenModal = () => setIsFullscreenModalOpen(false);

  const onOpen = (image, number, traits) => {
    setCurrentImage(image);
    setCurrentNumber(number);
    setCurrentTraits(traits);
    setIsOpen(true);
  };
  
  const onClose = () => {
    setIsOpen(false);
  };

  //функции для кнопок следующая и предыдущая модального окна
  const showNextImage = () => {
    const currentIndex = traitsData.findIndex((item) => item.number === currentNumber);
    const nextIndex = (currentIndex + 1) % traitsData.length;
    const { image, number, traits } = traitsData[nextIndex];
    setCurrentImage(image);
    setCurrentNumber(number);
    setCurrentTraits(traits);
  };
  
  const showPreviousImage = () => {
    const currentIndex = traitsData.findIndex((item) => item.number === currentNumber);
    const previousIndex = (currentIndex - 1 + traitsData.length) % traitsData.length;
    const { image, number, traits } = traitsData[previousIndex];
    setCurrentImage(image);
    setCurrentNumber(number);
    setCurrentTraits(traits);
  };
  
  const [marqueeSpeed, setMarqueeSpeed] = useState(50);
  const [marqueeSpeed2, setMarqueeSpeed2] = useState(30);

  useEffect(() => {
    const setSpeedForWindowSize = () => {
      if (window.innerWidth < 768) { // для мобильных устройств
        setMarqueeSpeed(20); 
        setMarqueeSpeed2(5); 
      } else {
        setMarqueeSpeed(50); // устанавливаем обычную скорость для других устройств
        setMarqueeSpeed2(30);
      }
    };
  
    setSpeedForWindowSize();
    window.addEventListener('resize', setSpeedForWindowSize);
  
    return () => {
      window.removeEventListener('resize', setSpeedForWindowSize);
    };
  }, []);

  return (
    <>
    <Box
      className="container"
      id="gallery"
      display="flex"
      flexDirection="column"
      alignItems="center"
      height={{ base: "100%", md: "80vh", lg: "100vh", xl: "100vh", "2xl": "80vh" }}
      width="100%"
      overflow="hidden"
      pt={{ base: "1vh", md:"5vh" }}
    >
      <Box maxWidth={{ base: "100vw", md: "60vw", xl: "50vw", "2xl": "33vw" }} mx="1rem">
        <Box as="h1" fontWeight="bold" textAlign="center" mb={{ base: "0.8rem", md: "1.7rem"}}>
          <Heading as="h2" size="lg">
            <Text className="section-header" textAlign="left" fontSize="1.7rem">
              Gallery
            </Text>
          </Heading>
        </Box>
        <Box
          as="p"
          fontSize="md"
          fontWeight="normal"
          textAlign="center"
          mb="2.7rem"
          maxW={{ base: '100vw', xl: '50vw', '2xl': '50vw' }}
        >
          <Text className="section-text" textAlign="left" fontSize="1.1rem" lineHeight="1.5rem">
          The collection comprises 15 types of traits: background, beard, body, cap, earring, eyes, hair, mouth, mustache, nose, outerwear, pendant, face tattoo, neck tattoo, and undershirt. With a total of 176 unique elements, the generation of two identical images is virtually impossible.
          </Text>
        </Box>
      </Box>
      <Box
        className="rows-container"
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{ cursor: 'pointer' }}
        onMouseEnter={(e) => e.target.closest('.rows-container').style.setProperty('animation-play-state', 'paused')}
        onMouseLeave={(e) => e.target.closest('.rows-container').style.setProperty('animation-play-state', 'running')}
      >
        <Marquee velocity={marqueeSpeed}>
        {traitsData.slice(0, 9).map(({image, number, traits}, index) => (
          <Box key={index} cursor="pointer" onClick={() => onOpen(image, number, traits)}>
            <Image src={image} alt={`Image ${index + 1}`} width={{ base: "75vw", md: "35vw", lg: "25vw", xl: "17vw", "2xl": "15vw" }} mx={{ base: "1vw", md: "0.3vw", xl: "0.2vw", '2xl': "0.1vw" }} />
          </Box>
        ))}
        </Marquee>
      </Box>
      <Box
        className="rows-container"
        display="flex"
        flexDirection="column"
        alignItems="center"
        onMouseEnter={(e) => e.target.closest('.rows-container').style.setProperty('animation-play-state', 'paused')}
        onMouseLeave={(e) => e.target.closest('.rows-container').style.setProperty('animation-play-state', 'running')}
      >
        <Marquee velocity={marqueeSpeed2}>
        {traitsData.slice(9).map(({image, number, traits}, index) => (
          <Box key={index} cursor="pointer" onClick={() => onOpen(image, number, traits)}>
            <Image src={image} alt={`Image ${index + 1}`} width={{ base: "75vw", md: "35vw", lg: "25vw", xl: "17vw", "2xl": "15vw" }} mx={{ base: "1vw", md: "0.3vw", xl: "0.2vw", '2xl': "0.1vw" }} />
          </Box>
        ))}
        </Marquee>
      </Box>



      {/* Модальное окно */}
      <Modal isOpen={isOpen} onClose={onClose} size="full" autoFocus={false}>
        <ModalOverlay />
        <ModalContent display="flex" justifyContent="center" alignItems="center" width="100%" bg="rgba(255, 255, 255, 0.9)">
          <Box
            position="absolute"
            top="3vh"
            left="3vw"
            onClick={openFullscreenModal}
            cursor="pointer"
          >
            <Image src={expand} alt="Open Fullscreen" boxSize="2.5rem" />
          </Box>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="center"
            width="100%"
            maxWidth={{ md: "calc(40vw + 45%)" }}
          >
            <Flex
              direction="column"
              justifyContent="center"
              width={{ md: "40vw" }}
            >
              <Box position="relative" width={{ md: "40vw" }}>
                <Image
                  src={currentImage}
                  alt="Selected Image"
                  maxW={{ base: "100vw", md: "40vw" }}
                  objectFit="contain"
                  onClick={openFullscreenModal}
                  cursor="pointer"
                />
                <Box
                  position="absolute"
                  top="50%"
                  left={{ base: "4vw" , md: "-6.5vw" }}
                  transform="translateY(-50%)"
                  onClick={showPreviousImage}
                  cursor="pointer"
                >
                  <Image src={left} alt="Previous Image" boxSize="2.3rem" />
                </Box>
                <Box
                  position="absolute"
                  top="50%"
                  right={{ base: "4vw" , md: "-6.5vw", '2xl': "-1vw" }}
                  transform="translateY(-50%)"
                  onClick={showNextImage}
                  cursor="pointer"
                >
                  <Image src={right} alt="Next Image" boxSize="2.3rem" />
                </Box>
              </Box>
            </Flex>

            <Flex
              direction="column"
              width={{ base: "100%", md: "45%" }}
              pl={{ base: "2rem", md: 0 }}
              pt={{ base: "3rem", lg: "3rem" }}
            >
              <VStack
                spacing={1}
                width={{ base: "100%", md: "45%" }}
                pl={{ base: "2rem", md: 0 }}
                pt={{ base: "0", md: "0", lg: "3rem" }}
                alignItems="flex-start"
                ml={{ base: "0", md: "15vw" }}
              >
                <Heading as="h4" fontSize="1.8rem" fontFamily="'Tilt Warp', cursive" fontWeight="400" mb={7}>Franki #{currentNumber}</Heading>
                {currentTraits.map((trait, index) => (
                  <Text key={index} className="section-text" fontSize="1.2rem" fontWeight="400" lineHeight="1.4rem">{trait}</Text>
                ))}
                <Link pt={{ base: "1rem", md: "3rem" }} pb={{base: "2rem", md: "0"}} textDecoration="underline" fontFamily="'Questrial', sans-serif" fontWeight="500" fontSize="1.1rem"
                href={`https://opensea.io/assets/ethereum/0x9bb9aab01b1aa61d6be5968d60798aa4b93d12d5/${currentNumber}`} isExternal>
                  View on OpenSea
                </Link>
              </VStack>
            </Flex>
          </Flex>
          <Box
            position="absolute"
            top={{ base: "2.7vh", md: "3.3vh" }}
            right={{ base: "5vw", md: "3vw" }}
            onClick={onClose}
            cursor="pointer"
            zIndex="1"
          >
            <Image src={close} alt="Close Modal" boxSize={{ base: "1.5rem", md: "2.5rem" }} />
          </Box>
        </ModalContent>
      </Modal>

      {/* Модальное окно для полноэкранного изображения */}
      <Modal isOpen={isFullscreenModalOpen} onClose={closeFullscreenModal} size="full">
        <ModalOverlay />
        <ModalContent display="flex" justifyContent="center" alignItems="center" width="100%" height="100%" p={0} m={0}>
          <Image
            src={currentImage}
            alt="Fullscreen Image"
            objectFit="contain"
            width="100%"
            height="100%"
            onClick={closeFullscreenModal}
            cursor="pointer"
          />
          <Box
            position="absolute"
            top="3vh"
            right="3vw"
            onClick={closeFullscreenModal}
            cursor="pointer"
            aria-label="Close Fullscreen"
            className='modal-button'
          >
            <Image src={collapse} alt="Collapse" boxSize={{ base: "1.5rem", md: "2.5rem" }} />
          </Box>
        </ModalContent>
      </Modal>
    </Box>
    </>
  );
};

export default Gallery;

       
