import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import randomness from '../assets/Crypto Franki-randomness.png';
import ipfs from '../assets/Crypto Franki-IPFS.png';

const FeaturesSection = () => {
  return (
  <Flex
    flexDirection={{ base: "column", lg: "row" }}
    alignItems="center"
    justifyContent="center"
    flexWrap="wrap"
    mx="auto"
    pt={{ base: "2.5rem", lg: "4rem" }}
    pb="1rem"
  >
    <Box maxWidth={{ base: "90vw", md: "90vw", lg: "40vw", xl: "31vw", "2xl": "25vw" }} mr={{ base:"0", lg:"1vw"}}>
      <Flex alignItems="center" flexDirection={{ base: "column", md: "row" }}>
        <Image
          src={randomness}
          alt="Randomness of mint"
          width={{ base: "50%", md: "17rem" }}
          height="auto"
          objectFit="contain"
        />
        <Box textAlign="left" mt={{ base: "1rem"}} ml={{ base: "0"}}>
          <Text fontWeight="bold" fontFamily="'Rammetto One', cursive" mb="0.7rem">
            In-contract randomness
          </Text>
          <Text fontSize="1.1rem" fontWeight="500" lineHeight="1.5rem">
          Each Crypto Franki NFT is randomly minted by a smart contract, considering factors like block complexity, gas limit, timestamp, miner's address, and minter's address. This guarantees every Franki's uniqueness and non-replicability.
          </Text>
        </Box>
      </Flex>
    </Box>
    <Box maxWidth={{ base: "90vw", md: "90vw", lg: "40vw", xl: "31vw", "2xl": "25vw" }} pt={{ base: "1.5rem", lg: "0" }}>
      <Flex alignItems="center" flexDirection={{ base: "column", md: "row" }}>
        <Image
          src={ipfs}
          alt="IPFS Immutability"
          width={{ base: "50%", md: "15rem" }}
          height="auto"
          objectFit="contain"
        />
        <Box ml={{ base: "0", md: "2rem"}} textAlign="left" mt="1rem" >
          <Text fontFamily="'Rammetto One', cursive" mb="0.7rem">IPFS Immutability</Text>
          <Text fontSize="1.1rem" fontWeight="500" lineHeight="1.5rem">
          Each Franki image is stored indefinitely on the IPFS, a distributed network for a faster, safer, and more open web. This ensures your Franki remains accessible and viewable forever. Learn more at https://ipfs.io/#how.
          </Text>
        </Box>
      </Flex>
    </Box>
  </Flex>
  )
};
  
export default FeaturesSection;

