import './App.css';
import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { ChakraProvider, useToast, Box, Heading, VStack, HStack, IconButton, Button, Text, Alert, AlertIcon, AlertTitle, Image, Flex, Link, 
  useDisclosure, Drawer, DrawerOverlay, DrawerContent, DrawerBody, DrawerCloseButton } from "@chakra-ui/react";
import { Link as ScrollLink } from "react-scroll";

import { FiMenu } from 'react-icons/fi';

import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from "@web3-react/core";
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { FortmaticConnector } from '@web3-react/fortmatic-connector';
import { MdCheckCircle, MdError, MdInfoOutline, MdWarning } from 'react-icons/md';


import contractABI from './assets/contractABI.json';

//sections
import MintSection from './components/MintSection';
import FeaturesSection from './components/FeaturesSection';
import Gallery from "./components/Gallery";
import AboutSection from "./components/AboutSection";
import TeamSection from "./components/TeamSection";
import HeaderMarketSocial from "./components/HeaderMarketSocial";
import theme from "./theme";

//dialogs
import ConnectDialog from './dialogs/connectDialog.js';
import DisconnectDialog from './dialogs/disconnectDialog.js';
import MintNFTModal from './dialogs/mintDialog.js';
import ShowCreated from './dialogs/ShowCreated.js';

//assets
import logo from "./assets/Crypto-Franki-Logo.png";
import disconnectedWallet from "./assets/Icons site/disconnectedwallet.png";
import connectedWallet from "./assets/Icons site/connectedwallet.png";
import backgroundImage from "./assets/Crypto-Franki-titul.jpg";
import smallImage from "./assets/Crypto Franki Rabbit.png";
import openseaLong from './assets/Icons site/opensealong.png';
import raribleLong from './assets/Icons site/rariblelong.png';
import looksrareLong from './assets/Icons site/looksrarelong.png';
import './assets/fonts/fonts.css';


const MarginTop = ({ height }) => (
  <div style={{ marginTop: height }}></div>
);

const contractAddress = '0x9bb9aAB01b1Aa61d6Be5968d60798aA4B93D12D5'; 
//const contractAddress = '0x6626da75fc04736e83a57322f73c789ef9b40044'; //rinkeby testnet

// CONNECTORS
const injected = new InjectedConnector({ supportedChainIds: [1, 3, 4, 5, 42, 10, 100, 56, 97, 137, 80001, 250, 4002, 43114, 43113, 1284, 1285, 1666600000, 1666700000] });
const walletconnect = new WalletConnectConnector({
  rpc: { 1: 'https://eth.llamarpc.com' },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 12000,
});
const fortmatic = new FortmaticConnector({
  apiKey: process.env.REACT_APP_FORTMATIC_API_KEY,
  chainId: 1,
});

const isMobileDevice = () => {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
};


function App() {
  const [walletAddress, setWalletAddress] = useState('');
  const [walletConnected, setWalletConnected] = useState(false);
  const [isTestContract, setIsTestContract] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { chainId } = useWeb3React();
  const {    activate,    deactivate,    active,    account,    error,  library} = useWeb3React();

  const toast = useToast();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


  //if test contract is on
  useEffect(() => {
    // Условие определения тестового контракта
    if (contractAddress === "0x6626da75fc04736e83a57322f73c789ef9b40044") {
      setIsTestContract(true);
    } else {
      setIsTestContract(false);
    }
    // Определите поддерживаемые идентификаторы цепочки в зависимости от тестового контракта
    const supportedChainIds = isTestContract ? [1, 5] : [1];
    if (active && !supportedChainIds.includes(chainId)) {
      alert('You are connected to an unsupported network. Please switch to one of the supported networks.');
      switchToMainnet();
    }
  }, [active, chainId, contractAddress, isTestContract]);
  
  const showTestContractToast = () => {
    toast({
      title: 'Attention!',
      description: 'You are using a test contract.',
      status: 'warning',
      duration: 10000, // Закрытие через 10 секунд
      isClosable: true,
      position: 'top', // Выберите позицию, где отображается уведомление
    });
  };

  useEffect(() => {
    if (isTestContract) {
      showTestContractToast();
    }
  }, [isTestContract]);

  const truncateAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };


  //wallets
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [showDisconnectModal, setshowDisconnectModal] = useState(false);

  const openWalletModal = () => {    setShowWalletModal(true);  };
  const closeWalletModal = () => {    setShowWalletModal(false);  };

  const openDisconnectModal = () => {    setshowDisconnectModal(true);  };
  const closeDisconnectModal = () => {    setshowDisconnectModal(false);  };


  // Logic to connect or disconnect wallet and refresh states
  const connectWallet = async (connector) => {
    try {
      await activate(connector, undefined, true);
    } catch (error) {
      console.log("Connection error:", error);
      //show advise to connect to metamask built-in browser on mobiles
      if (!window.ethereum && isMobileDevice()) {
        toast({
          position: 'top',
          duration: 3000,
          isClosable: true,
          render: () => (
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              bg="blue.500"
              borderRadius="md"
              boxShadow="md"
              p={4}
              color="white"
            >
              <MdInfoOutline size="1.5rem" />
              <Text fontWeight="bold" mt={2}>
                Please use the MetaMask built-in browser.
              </Text>
            </Flex>
          ),
        });
      }
    }
  };

  useEffect(() => {
    if (account) {
      setWalletConnected(true);
      setWalletAddress(truncateAddress(account));
      closeWalletModal(); // Закрытие модального окна
    } else {
      setWalletConnected(false);
      setWalletAddress('');
    }
  }, [account]);
  
  const switchToMainnet = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }],
        });
      } catch (error) {
        console.error('Error switching to mainnet:', error);
      }
    }
  };

  const disconnectWallet = () => {
    setWalletConnected(false);
    deactivate();
    closeDisconnectModal()
  };


  // ---------------------------------------------------------- MINT NFT
  const [numNFTsToMint, setNumNFTsToMint] = useState(1);
  const [createdNFTs, setCreatedNFTs] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showNFTLinksModal, setShowNFTLinksModal] = useState(false);


  async function mintNFT(numberOfTokens, library) {
    if (!active) {
      alert("Install MetaMask or connect your wallet!");
    }
  
    if (!walletConnected) {
      openWalletModal();
    } else {
      try {
        const web3 = new Web3(library.provider);
        const accounts = await web3.eth.getAccounts();

        const contract = new web3.eth.Contract(contractABI, contractAddress);
        
        //Check user funds and toast warning if not enouph
        const gasPrice = await web3.eth.getGasPrice();
        const gasPerToken = 200000;
        const totalGasCost = Web3.utils.toBN(gasPrice).mul(Web3.utils.toBN(numberOfTokens * gasPerToken));
        const userBalance = Web3.utils.toBN(await web3.eth.getBalance(accounts[0]));
        if (userBalance.lt(totalGasCost)) {
          toast({
            position: 'top',
            duration: 5000,
            isClosable: true,
            render: () => (
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                bg="yellow.400"
                borderRadius="md"
                boxShadow="md"
                p={4}
                color="black"
              >
                <MdWarning size="1.5rem" />
                <Text fontWeight="bold" mt={2}>
                Insufficient funds to cover network fees. Please top up your Ethereum wallet.
                </Text>
              </Flex>
            ),
          });
          return; 
        }

        const price = await contract.methods.PRICE_PER_TOKEN().call();
        const numberOfTokensBN = Web3.utils.toBN(numberOfTokens);
        const totalCost = Web3.utils.toBN(price).mul(numberOfTokensBN);
  
        toast({
          position: 'top',
          duration: 3000,
          isClosable: true,
          render: () => (
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              bg="blue.500"
              borderRadius="md"
              boxShadow="md"
              p={4}
              color="white"
            >
              <MdInfoOutline size="1.5rem" />
              <Text fontWeight="bold" mt={2}>
                Minting in progress...
              </Text>
            </Flex>
          ),
        });
        
  
        const transaction = await contract.methods
          .mint(numberOfTokens)
          .send({ value: totalCost, from: accounts[0] });
  
        // Обновите состояние статуса минтинга
        toast({
          position: 'top',
          duration: 3000,
          isClosable: true,
          render: () => (
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              bg="green.500"
              borderRadius="md"
              boxShadow="md"
              p={4}
              color="white"
            >
              <MdCheckCircle size="1.5rem" />
              <Text fontWeight="bold" mt={2}>
                You have successfully minted {numberOfTokens} NFT(s)!
              </Text>
            </Flex>
          ),
        });
        
  
        // Получите созданные NFT и отобразите их
        const nftIds = await contract.methods.walletOfOwner(accounts[0]).call();
        displayCreatedNFTs(nftIds);
  
      } catch (error) {
        if (isTestContract) { setErrorMessage(`Error while minting NFT: ${error.message}`); }
        console.error("Error while minting NFT", error);
        toast({
          position: 'top',
          duration: 5000,
          isClosable: true,
          render: () => (
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              bg="red.500"
              borderRadius="md"
              boxShadow="md"
              p={4}
              color="white"
            >
              <MdError size="1.5rem" />
              <Text fontWeight="bold" mt={2}>
                Error while minting NFT
              </Text>
            </Flex>
          ),
        });
        
      }
    }
  }
  
  // Функции для отображения созданных NFT
  async function displayCreatedNFTs(nftIds) {
    const provider = new Web3(window.ethereum);
    const contract = new provider.eth.Contract(contractABI, contractAddress);
    const nftPromises = nftIds.map(async (nftId) => {
      const tokenURI = await contract.methods.tokenURI(nftId).call();
      console.log('Token URI:', tokenURI);
  
      const response = await fetch(tokenURI);
      if (!response.ok) {
        console.error("Error fetching token URI:", response.status, response.statusText);
        throw new Error("Error fetching token URI");
      }
      
      const responseText = await response.text();
      console.log('Response:', responseText);
  
      const metadata = JSON.parse(responseText);
      const image = "https://" + metadata.image; // Add 'https://' before the image URL
      const openSeaLink = `https://opensea.io/assets/${contractAddress}/${nftId}`;
      const raribleLink = `https://rarible.com/token/${contractAddress}:${nftId}`;
      const looksRareLink = `https://looksrare.org/collections/${contractAddress}/${nftId}`;
      return { image, openSeaLink, raribleLink, looksRareLink };
    });
  
    const nftData = await Promise.all(nftPromises);
    setCreatedNFTs(nftData);
  
    // Call the openNFTLinksModal function after displaying the created NFTs
    openNFTLinksModal();
  }

  // Function to display NFT links modal
  function openNFTLinksModal() {
    setShowNFTLinksModal(true);
  }

  // Function to close NFT links modal
  function closeNFTLinksModal() {
    setShowNFTLinksModal(false);
  }
  
  
  

  



  return (
    <ChakraProvider theme={theme}>
      <Box bgColor="#fafafa" >
      {isTestContract && errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}
        <VStack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: '4', md: '8' }}
          align="center"
          w="100%"
        >
          
          {/* HEADER */}
          <Flex
            justifyContent="space-between"
            w="100%"
            px={{ base: '1rem', lg: '2rem', xl: '5rem', '2xl': '10rem' }}
            height="5.8125rem"
          >
            {/* Logo */}
            <Box display="flex" alignItems="center">
              <Image
                src={logo}
                alt="Crypto Franki Logo"
                display="inline-block"
                // height={{ base: "2rem", lg: "3rem", xl: "3rem", '2xl': "3rem" }}
                height="auto"
                width="3.125rem"
              />
              <Heading
                as="h2"
                ml="0.93rem"
                fontSize="1.35rem"
                fontFamily="'Tilt Warp', cursive"
                fontWeight="300"
              >
                Crypto Franki
              </Heading>
            </Box>

            <Box display="flex" alignItems="center" gap={{ base: "1rem", lg: "4rem", xl: "6rem", '2xl': "8rem" }}>
              <Box display={{ base: "none", md: "flex" }}>

                <HeaderMarketSocial/>

                {/* Site refs */}
                <Box display="flex" alignItems="center" fontFamily="'Tilt Warp', cursive" fontWeight="500" fontSize="1.1rem">
                  <Link
                    href="#gallery"
                    cursor="pointer"
                    _hover={{ textDecoration: 'none', color: 'rgb(255,61,111)'}}
                  >
                    Gallery
                  </Link>
                  <Link
                    href="#about"
                    mx={{ base: "1rem", md: "1rem", xl: "2rem", '2xl': "2rem" }}
                    cursor="pointer"
                    _hover={{ textDecoration: 'none', color: 'rgb(255,61,111)'}}
                  >
                    About Franki
                  </Link>
                  <Link
                    href="#team"
                    cursor="pointer"
                    _hover={{ textDecoration: 'none', color: 'rgb(255,61,111)'}}
                  >
                    Team
                  </Link>
                </Box>
              </Box>

              {/* Connect button */}
              <HStack>
              <Button
                display={{ base: "none", lg: "flex" }} // don't show on mobiles
                borderRadius="20rem" // Задает форму горизонтальной пилюли
                borderWidth={walletConnected ? "0" : "0.14rem"} // Задает толщину рамки
                borderColor={walletConnected ? "transparent" : "#091405"} // Задает цвет рамки
                bgColor="#fafafa"
                fontFamily="'Questrial', sans-serif"
                fontWeight="700"
                fontSize="1.07rem"
                _hover={{ backgroundColor: "hsl(0, 0%, 100%)" }}
                onClick={walletConnected ? openDisconnectModal : openWalletModal}
                mr={walletConnected ? "0" : "0.7rem" }
                width="9rem"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                height="2.2rem"
              >
                {walletConnected ? (
                  <Text>{truncateAddress(walletAddress)}</Text>
                ) : (
                  "Connect wallet"
                )}
              </Button>
                {/* Wallet pic */}
                <Image
                  src={walletConnected ? connectedWallet : disconnectedWallet}
                  alt="Connect Wallet"
                  onClick={walletConnected? openDisconnectModal : openWalletModal}
                  cursor="pointer"
                  // boxSize="3rem"
                  width="2.55rem"
                />
              </HStack>

              {/* Бутерброд для Action menu на мобильниках */}
              <Box display={{ base: "block", md: "none" }}>
                <IconButton
                  aria-label="Open menu"
                  onClick={() => setIsMobileMenuOpen(true)}
                  bg="transparent"
                  fontSize="1.5rem"
                  icon={<FiMenu />}
                />
              </Box>
              <Drawer isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)}>
                <DrawerOverlay>
                  <DrawerContent>
                    <DrawerCloseButton  mt={6} mr={3} fontSize="1.5rem" />
                    <DrawerBody>
                      <VStack spacing={6} align="start" mt="30vh">
                        <ScrollLink
                          to="gallery"
                          smooth={true}
                          duration={500}
                          onClick={() => setIsMobileMenuOpen(false)}
                        >
                          <Text fontSize="1.5rem" fontFamily="Questrial, sans-serif">
                            Gallery
                          </Text>
                        </ScrollLink>

                        <ScrollLink
                          to="about"
                          smooth={true}
                          duration={500}
                          onClick={() => setIsMobileMenuOpen(false)}
                        >
                          <Text fontSize="1.5rem" fontFamily="Questrial, sans-serif">
                            About Franki
                          </Text>
                        </ScrollLink>

                        <ScrollLink
                          to="team"
                          smooth={true}
                          duration={500}
                          onClick={() => setIsMobileMenuOpen(false)}
                        >
                          <Text fontSize="1.5rem" fontFamily="Questrial, sans-serif">
                            Team
                          </Text>
                        </ScrollLink>
                      </VStack>
                    </DrawerBody>
                  </DrawerContent>
                </DrawerOverlay>
              </Drawer>


            {/* Modal dialog to connect wallet */}
            <ConnectDialog
              showWalletModal={showWalletModal}
              closeWalletModal={closeWalletModal}
              connectWallet={connectWallet}
              injected={injected}
              walletconnect={walletconnect}
              fortmatic={fortmatic}
              deactivate={deactivate}
              setWalletConnected={setWalletConnected}
              setWalletAddress={setWalletAddress}
              walletConnected={walletConnected}
            />
             {/* Modal dialog to disconnect wallet */}
             <DisconnectDialog isOpen={showDisconnectModal} onClose={closeDisconnectModal} onDisconnect={disconnectWallet} />


            </Box>
          </Flex>


          {/* Mint Section */}
          <MintSection
            walletConnected={walletConnected}
            openWalletModal={openWalletModal}
            onOpen={onOpen}
            backgroundImage={backgroundImage}
            smallImage={smallImage}
            mintNFT={mintNFT}
            numNFTsToMint={numNFTsToMint}
          />

          {/* Dialogue to mint NFTs */}
          <MintNFTModal
            isOpen={isOpen}
            onClose={onClose}
            numNFTsToMint={numNFTsToMint}
            setNumNFTsToMint={setNumNFTsToMint}
            createdNFTs={createdNFTs}
            mintNFT={mintNFT}
            library={library}
            active={active}
          />


          {/* Modal to show created nfts */}
          <ShowCreated
            isOpen={showNFTLinksModal}
            onClose={closeNFTLinksModal}
            createdNFTs={createdNFTs}
          />



          {/* Для мобильного отображения Маркетплейсов и соц сетей */}
          <Box
            display={{ base: "flex", md: "none" }}
            justifyContent="center"
            alignItems="center"
            gap="1rem"
            mt="2rem"
            mb="1rem"
          >
          <HeaderMarketSocial/>
          </Box>


          {/* In-contract randomness and IPFS Immutability */}
          <FeaturesSection/>



          {/* Gallery Section */}
          <Gallery />



          {/* Marketplaces */}
          <Flex
            bgColor="#eaeaea"
            w="100%"
            mt={{ base: "0", md: "1vh !important" }}
            height={{ base: "auto", md: "20vh" }}
            justifyContent="center"
            alignItems="center"
            flexDirection={{ base: "column", md: "row" }}
          >
            <a href="https://opensea.io/collection/crypto-franki" target="_blank" rel="noreferrer">
              <Image src={openseaLong} alt="Opensea" width={{ base: "70vw", md: "25vw", lg: "12vw"}} 
                maxHeight={{ base: "auto", md: "auto" }} objectFit="contain" mb={{ base:"2.5vh", md: 0 }} mt={{ base:"3vh", md: 0 }}
              />
            </a>
            <a href="https://rarible.com/collection/0x9bb9aab01b1aa61d6be5968d60798aa4b93d12d5" target="_blank" rel="noreferrer">
              <Image src={raribleLong} alt="Rarible" width={{ base: "70vw", md: "25vw", lg: "12vw"}} 
                maxHeight={{ base: "auto", md: "auto" }} objectFit="contain" mx={{ base: 0, md: "7.5vw" }} my={{ base:"2.5vh", md: 0 }} 
              />
            </a>
            <a href="https://looksrare.org/collections/0x9bb9aAB01b1Aa61d6Be5968d60798aA4B93D12D5" target="_blank" rel="noreferrer">
              <Image src={looksrareLong} alt="Looksrare" width={{ base: "70vw", md: "25vw", lg: "12vw" }} 
                maxHeight={{ base: "auto", md: "auto" }} objectFit="contain" mt={{ base:"2.5vh", md: 0 }} mb={{ base:"3vh", md: 0 }}
              />
            </a>
          </Flex>



          {/* About Crypto Franki */}
          <AboutSection />

          {/* Meet the Team */}
          <TeamSection width="100%" bgColor="red" height="100%"/>

          {/* Contract Address */}
          {/* <MarginTop height="10vh" /> */}
          <Box textAlign="center" bgColor="#eaeaea" w="100%" h="12vh" mt={{ base:"3rem !important", md:"0px !important"}} >
            <Flex justifyContent="center" alignItems="center" h="100%">
              <Flex width="100%" flexDirection={{ base: "column", md: "row"}} alignItems="center" justifyContent="center">
                <Text fontWeight="700" fontSize="1rem">
                  Contract address:
                </Text>
                <Text ml={2} fontWeight="500" fontSize={{ base: "0.85rem", md: "1rem"}}>
                  {contractAddress}
                </Text>
              </Flex>
            </Flex>
          </Box>



          {/* Footer */}
          <Box as="footer" w="100%" mt={{ base:"1.7rem !important", md:"0px"}}>
            <VStack alignItems="center" mb={4}>
              <Flex justifyContent="center" alignItems="center" mb="3vh" pr={{ base: "0", md: "1rem"}}>
                <Image src={logo} height="auto"  width={{ base: "4rem", md: "3.5rem"}} />
                <Text fontSize="2xl" className='section-header' ml="1rem">
                  Crypto Franki 
                </Text>
                <Text className='section-header' fontSize="sm" ml="0.7rem" mt="0.5rem" alignSelf="flex-start">
                  ©
                </Text>
              </Flex>
              <Text fontFamily="Poppins">
                franki.in
              </Text>
            </VStack>

            <Box bgColor="white" width="100%" py={7} position="relative"> {/* Increase the py value */}
              <Text position="absolute" bottom={0} left="50%" transform="translateX(-50%)" pb="1.1rem" fontSize="0.8rem">
                @vol_de_mar_art, 2023
              </Text>
            </Box>
          </Box>
        </VStack>
      </Box>
    </ChakraProvider>
  );
}

export default App;
