import React from 'react';
import { Box, Heading, Text, Image, Flex } from '@chakra-ui/react';
import image from './../assets/Crypto Franki-About.png';

const AboutSection = () => {
  return (
    <Flex justifyContent="center" mb={8} pb="5rem" pt={{ base: '1rem', md: '3rem' }} id="about" flexDirection={{ base: 'column', md: 'row' }}>
      <Box textAlign="left" width={{ base: '100%', md: '80%', lg: '60%' , '2xl': '50%'}} px="1rem">
        <Flex alignItems="center" flexDirection={{ base: 'column', md: 'row' }}>
          <Box>
            <Heading as="h2" size="lg" mb={4}>
            <Box display="flex" alignItems="center">
            <Text fontSize="1.7rem">
  <Box as="span" className="section-header header-normal" color="#034459">
    About
  </Box>
  <Box as="span" className="section-header header-normal" marginLeft="1rem">
    Crypto Franki
  </Box>
</Text>

              </Box>
            </Heading>
              <Text mt={{ base: "0.8rem", md: "1.7rem"}} fontSize="1.1rem" lineHeight="1.5rem">
              Franki is a fictional character inspired by ancient tribes that once inhabited modern-day Europe. 
              </Text>
              <Text pt="1rem" fontSize="1.1rem" lineHeight="1.5rem">
              Drawn in the Franco-Belgian comic style, Franki's lines were created using a unique pen, with the artist placing significant emphasis on expressing the character's emotions. Randomly generated traits produce distinctive images, such as a rocker, hippie, or punk.
              </Text>
              <Text pt="1rem" fontSize="1.1rem" lineHeight="1.5rem">
              Freedom-loving, cheerful, and possessing a great sense of humor, Franki enjoys experimenting with new looks. He has three pets: two rabbits and a monkey, who are always in sync with his vibe. If you're looking for a good time, just call Franki! 
              </Text>
              <Text pt="1rem" fontSize="1.1rem" mb={{md:"3rem"}} lineHeight="1.5rem">
              In short, Franki is one cool dude! :P
              </Text>
          </Box>
          <Image
            src={image}
            alt="Crypto Franki"
            boxSize="100%"
            objectFit="cover"
            borderRadius="md"
            ml={{ base: 0, xl: "2rem" , '2xl': "2rem"}}
            mt={{ base: 4, lg: 0, xl: 0 , '2xl': 0}}
            alignSelf="flex-start"
            width={{ base: '100vw', md: '31vw' , '2xl': '30vw'}}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default AboutSection;
