import React from 'react';
import { Box, Image, Link } from "@chakra-ui/react";
import { FaTwitter, FaDiscord } from "react-icons/fa";

import openseaIcon from './../assets/Icons site/opensea.png';
import raribleIcon from './../assets/Icons site/rarible.png';
import looksrareIcon from './../assets/Icons site/looksrare.png';

const HeaderMarketSocial = () => {
    return (
    <>
         <Box display="flex" alignItems="center" ml={{ base: "-3rem", md: "0" }}>
            <Link href="https://opensea.io/collection/crypto-franki" target="_blank" rel="noreferrer" mr={{ base: "1rem", md:"1.5rem"}}>
            <Image src={openseaIcon} alt="Opensea" width={{ base: "2.7rem", md:"2rem"}} height="auto" />
            </Link>
            <Link href="https://rarible.com/collection/0x9bb9aab01b1aa61d6be5968d60798aa4b93d12d5" target="_blank" rel="noreferrer"  mr={{ base: "1rem", md:"1.5rem"}}>
            <Image src={raribleIcon} alt="Rarible" width={{ base: "2.7rem", md:"2rem"}} height="auto"/>
            </Link>
            <Link href="https://looksrare.org/collections/0x9bb9aAB01b1Aa61d6Be5968d60798aA4B93D12D5" target="_blank" rel="noreferrer">
            <Image src={looksrareIcon} alt="Rarible" width={{ base: "3rem", md:"2rem"}} height="auto"/>
            </Link>
        </Box>

        <Box display="flex" alignItems="center" mx={{ base: "1vw", md: "6vw" }} zIndex="3">
        <Link href="https://twitter.com/Franki_NFT" target="_blank" rel="noreferrer" mr={{ base: "1rem", md:"1.5rem"}}>
            <Box as={FaTwitter} fontSize={{ base: "2.4rem", md: "1.7rem" }} />
        </Link>
        <Link href="https://discord.gg/szgTasbyvZ" target="_blank" rel="noreferrer">
            <Box as={FaDiscord} fontSize={{ base: "2.7rem", md: "1.7rem" }} />
        </Link>
        </Box>
    </>
    )
}

export default HeaderMarketSocial;
