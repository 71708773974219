import React from 'react';
import {
  HStack,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';

const ShowCreated = ({ isOpen, onClose, createdNFTs }) => {
  const handleError = (e) => {
    console.error('Error loading image:', e);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader></ModalHeader> */}
        <ModalHeader fontWeight="700" ml={8}>Newly Minted NFTs</ModalHeader>
        <ModalCloseButton size="lg" color="gray.500"/>
        <ModalBody>
          <VStack spacing={4}>
            {createdNFTs.map((nft, index) => (
              <HStack key={index} justifyContent="center" width="100%" alignItems="flex-start" flexWrap="wrap" spacing={8}>
                <Text fontWeight="bold" mb={2}>{`NFT ${index + 1}`}</Text>
                <Image
                  src={nft.image}
                  alt={`NFT ${index + 1}`}
                  boxSize={{ base: "6rem", md: "17rem" }}
                  onError={handleError}
                  mb={2}
                />
                <VStack spacing={1} alignItems="flex-start">
                  <Link href={nft.openSeaLink} target="_blank" rel="noopener noreferrer">
                    OpenSea
                  </Link>
                  <Link href={nft.raribleLink} target="_blank" rel="noopener noreferrer">
                    Rarible
                  </Link>
                  <Link href={nft.looksRareLink} target="_blank" rel="noopener noreferrer">
                    LooksRare
                  </Link>
                </VStack>
              </HStack>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShowCreated;
