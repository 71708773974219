import React from 'react';
import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import metamaskIcon from './../assets/Icons site/metamask.png';
import walletconnectIcon from './../assets/Icons site/walletconnect.png';
import fortmaticicon from './../assets/Icons site/fortmatic.png';



const CustomButton = ({ icon: IconComponent, src, text, onClick, width }) => {
    return (
      <Button
        onClick={onClick}
        background="transparent"
        fontWeight="700"
        width='100%'
        height="3.5rem"
        borderColor="gray.300"
        borderWidth={1}
        borderRadius="0.7rem"
        _hover={{ backgroundColor: '#f2f2f2', borderColor:"rgb(186,186,186)"}}
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center" width="100%">
          <IconComponent boxSize={width} src={src} ml="0.15rem" mr="0.5rem" _hover={{ color: 'red'}} />
          <Text ml={2} fontSize="1.1rem" fontFamily="PoppinsBold" fontWeight="600">{text}</Text>
        </Box>
      </Button>
    );
  };

const ConnectDialog = ({
    walletConnected,
    showWalletModal,
    closeWalletModal,
    connectWallet,
    injected,
    walletconnect,
    fortmatic,
    deactivate,
    setWalletConnected,
    setWalletAddress,
  }) => {
    return (
      showWalletModal && (
        <Modal isOpen={showWalletModal} onClose={closeWalletModal} isCentered >
          <ModalOverlay />
          <ModalContent  mt={["-18vh", "-5vh", "-5vh"]} borderRadius="1rem" width={{xl: "460px"}}>
            <VStack spacing={2} alignItems="flex-start" width="80%" mx="auto">
              <ModalHeader mt={0}></ModalHeader>
              <ModalHeader fontWeight="500" fontSize="1.65rem" mb={0} pb={0} fontFamily="'Tilt Warp', cursive">
                Connect Wallet
              </ModalHeader>
              <ModalHeader mt={0} pt={0} fontWeight="500" fontFamily="Poppins" fontSize="1.1rem">
                Choose how you want to connect
              </ModalHeader>
            </VStack>
            <ModalCloseButton size="lg" color="gray.300" />
            <ModalBody>
              <VStack spacing={4} alignItems="center" width="80%" mx="auto" mb="3rem">
                <CustomButton
                  icon={Image}
                  width="2rem"
                  src={metamaskIcon}
                  text="MetaMask"
                  onClick={() => connectWallet(injected)}
                />
                <CustomButton
                  icon={Image}
                  width="2rem"
                  src={walletconnectIcon}
                  text="WalletConnect"
                  onClick={() => connectWallet(walletconnect)}
                />
                <CustomButton
                  icon={Image}
                  width="1.8rem"
                  src={fortmaticicon}
                  text="Fortmatic"
                  onClick={() => connectWallet(fortmatic)}
                />
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )
    );
  };
  
  export default ConnectDialog;
  
  