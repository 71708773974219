import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Image,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';


const MintNFTModal = ({ isOpen, onClose, numNFTsToMint, setNumNFTsToMint, createdNFTs, mintNFT, library, active  }) => {
    return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="1rem">
        <ModalHeader/>
        <ModalHeader fontFamily="TiltWarp" fontWeight="400 !important" >Enter the number of NFTs to mint</ModalHeader>
        <ModalCloseButton size="lg" color="gray.300" />
        <ModalBody>
          <FormControl>
            <FormLabel fontFamily="PoppinsMedium" fontSize="1.1rem" >Number of NFTs to mint</FormLabel>
            <NumberInput borderColor="rgb(148, 149, 147)" bg="rgb(246, 250, 245)"
            min={1} max={20} value={numNFTsToMint} onChange={(value) => setNumNFTsToMint(value)}>
              <NumberInputField borderRadius="0.6rem" fontFamily="Poppins" height="2.8rem"/>
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          {/* Grid to show created NFTs */}
          {/* <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)", xl: "repeat(4, 1fr)" }}
            gap={6}
          >
            {createdNFTs.map((nft, index) => (
              <GridItem key={index}>
                <Box
                  maxW="sm"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  boxShadow="lg"
                >
                  <Link href={nft.openSeaLink} isExternal>
                    <Image
                      src={nft.image}
                      alt={`NFT ${index + 1}`}
                      width="100%"
                      height="auto"
                    />
                  </Link>
                </Box>
              </GridItem>
            ))}
          </Grid> */}
        </ModalBody>
        <ModalFooter>
          <Button  
          bgColor= "rgb(23,144,245)"
          color="rgb(246,249,245)"
          _hover={{ bgColor: 'rgb(255,61,110)'}}
          mr={3} 
          onClick={() => {
            mintNFT(numNFTsToMint, library);
            onClose();
          }}
          borderRadius="full"
          width="6.5rem"
          >
            <Text>Mint NFT</Text>
          </Button>
          <Button onClick={onClose} borderRadius="full" width="6.5rem">Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    )
  };

export default MintNFTModal;